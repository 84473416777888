<template>
<span
    class="kt-badge kt-badge--unified kt-badge--bold"
    :class="{[color]: true, 'kt-badge--xl': !small, 'kt-badge--sm': small}"
    style="font-size: 1rem;"
>
    {{ computedIcon }}
</span>
</template>

<script>

export default {
    name: 'CircleBadge',
    props: {
        color: {
            type: String,
            default: 'Grey',
        },
        icon: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        computedIcon() {
            if (this.icon) {
                return this.icon;
            }
            return (this.title || '').substr(0, 1);
        },
    },

};
</script>
