<template>
<div>
    <div class="kt-input-icon">
        <span
            v-if="selectedCategory"
            class="kt-input-icon__icon kt-input-icon__icon--left"
            @click.stop.prevent="clear()"
        >
            <span>
                {{ selectedCategory.categoryIcon }}
            </span>
        </span>
        <input
            v-model="searchTerm"
            type="text"
            style="background: transparent !important;"
            class="form-control"
            :class="selectedCategory ? 'pl-5' : ''"
            @blur="blurEventHandler($event)"
            @focus="focusEventHandler($event)"
            @keyup.13="selectCategory(0)"
        >
        <span
            v-if="selectedCategory"
            class="kt-input-icon__icon kt-input-icon__icon--right"
            @click.stop.prevent="clear()"
        >
            <span>
                <i class="la la-close" />
            </span>
        </span>
    </div>

    <div
        v-if="categories.length > 0 && showPicker"
        class="kt-widget4 p-3 mt-2"
    >
        <div
            v-for="(category, idx) in categories"
            :key="`${idx}_category`"
            class="kt-widget4__item"
            @click.stop.prevent="selectCategory(idx)"
        >
            <div class="kt-widget4__pic kt-widget4__pic--pic">
                <CircleBadge
                    :color="category.categoryColor"
                    :icon="category.categoryIcon"
                    :title="category.anecdotalEventCategoryTitle"
                />
            </div>

            <div class="kt-widget4__info">
                <a
                    href="#"
                    class="kt-widget4__username"
                    @click.stop.prevent="selectCategory(idx)"
                >
                    {{ category.anecdotalEventCategoryTitle }}
                </a>
            </div>

            <span
                v-if="category.pointValue != 0"
                :title="formatNumber(category.pointValue)"
                class="kt-point-badge ml-4"
                :class="{
                    'kt-font-success':category.pointValue > 0,
                    'kt-font-danger':category.pointValue < 0,
                }"
            >

                {{ formatNumber(category.pointValue) }}
            </span>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import Types from '../../store/Types';
import CircleBadge from '../../components/CircleBadge.vue';

export default {
    name: 'AnecdotalCategoryPicker',
    components: {
        CircleBadge,
    },
    props: {
        currentCategory: {
            type: Object,
            required: false,
            default: null,
        },
        onSelected: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            searchTerm: '',
            selectedCategory: null,
            showPicker: false,
            timeout: null,
        };
    },
    computed: {
        ...mapState({
            anecdotalEventCategories: (state) => state.database.anecdotalEventCategories,
        }),
        categories() {
            const searchTerm = ('' || this.searchTerm).toLowerCase();
            const anecdotalEventCategories = [...this.anecdotalEventCategories];
            return anecdotalEventCategories.filter((c) => {
                const search = `${c.anecdotalEventCategoryTitle}`.toLowerCase();
                return (search.indexOf(searchTerm.toLowerCase()) > -1);
            }).sort((a, b) => ((a.anecdotalEventCategoryTitle > b.anecdotalEventCategoryTitle) ? 1 : ((b.anecdotalEventCategoryTitle > a.anecdotalEventCategoryTitle) ? -1 : 0)));
        },
    },
    watch: {
        currentCategory() {
            this.selectedCategory = this.currentCategory;
            this.searchTerm = this.selectedCategory?.anecdotalEventCategoryTitle || '';
        },
    },
    mounted() {
        this.selectedCategory = this.currentCategory;
        this.searchTerm = this.selectedCategory?.anecdotalEventCategoryTitle || '';
    },
    methods: {
        clear() {
            this.selectedCategory = null;
            this.searchTerm = '';
            if (this.onSelected) {
                this.onSelected(null);
                return;
            }
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_CATEGORY, 0);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
        blurEventHandler() {
            const v = this;
            v.timeout = setTimeout(() => {
                v.showPicker = false;
            }, 600);
        },
        focusEventHandler() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.showPicker = true;
        },
        selectCategory(idx) {
            this.selectedCategory = this.categories[idx];
            this.searchTerm = `${this.selectedCategory.anecdotalEventCategoryTitle}`;
            this.showPicker = false;
            if (this.onSelected) {
                this.onSelected(this.selectedCategory);
                return;
            }
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_CATEGORY, this.selectedCategory.anecdotalEventCategoryId);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
    },
};
</script>

<style scoped>
.kt-widget4 {
    position: absolute;
    width: 85%;
    z-index: 2;
    background: white;
    border: 1px solid #e2e5ec;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: clip;
}
</style>
