var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "kt-input-icon" }, [
      _vm.selectedCategory
        ? _c(
            "span",
            {
              staticClass: "kt-input-icon__icon kt-input-icon__icon--left",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.clear()
                },
              },
            },
            [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.selectedCategory.categoryIcon) + " "),
              ]),
            ]
          )
        : _vm._e(),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchTerm,
            expression: "searchTerm",
          },
        ],
        staticClass: "form-control",
        class: _vm.selectedCategory ? "pl-5" : "",
        staticStyle: { background: "transparent !important" },
        attrs: { type: "text" },
        domProps: { value: _vm.searchTerm },
        on: {
          blur: function ($event) {
            return _vm.blurEventHandler($event)
          },
          focus: function ($event) {
            return _vm.focusEventHandler($event)
          },
          keyup: function ($event) {
            if (!$event.type.indexOf("key") && $event.keyCode !== 13)
              return null
            return _vm.selectCategory(0)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.searchTerm = $event.target.value
          },
        },
      }),
      _vm.selectedCategory
        ? _c(
            "span",
            {
              staticClass: "kt-input-icon__icon kt-input-icon__icon--right",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.clear()
                },
              },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
    ]),
    _vm.categories.length > 0 && _vm.showPicker
      ? _c(
          "div",
          { staticClass: "kt-widget4 p-3 mt-2" },
          _vm._l(_vm.categories, function (category, idx) {
            return _c(
              "div",
              {
                key: `${idx}_category`,
                staticClass: "kt-widget4__item",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.selectCategory(idx)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "kt-widget4__pic kt-widget4__pic--pic" },
                  [
                    _c("CircleBadge", {
                      attrs: {
                        color: category.categoryColor,
                        icon: category.categoryIcon,
                        title: category.anecdotalEventCategoryTitle,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "kt-widget4__info" }, [
                  _c(
                    "a",
                    {
                      staticClass: "kt-widget4__username",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.selectCategory(idx)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(category.anecdotalEventCategoryTitle) + " "
                      ),
                    ]
                  ),
                ]),
                category.pointValue != 0
                  ? _c(
                      "span",
                      {
                        staticClass: "kt-point-badge ml-4",
                        class: {
                          "kt-font-success": category.pointValue > 0,
                          "kt-font-danger": category.pointValue < 0,
                        },
                        attrs: { title: _vm.formatNumber(category.pointValue) },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatNumber(category.pointValue)) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "la la-close" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }