var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "kt-badge kt-badge--unified kt-badge--bold",
      class: {
        [_vm.color]: true,
        "kt-badge--xl": !_vm.small,
        "kt-badge--sm": _vm.small,
      },
      staticStyle: { "font-size": "1rem" },
    },
    [_vm._v(" " + _vm._s(_vm.computedIcon) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }